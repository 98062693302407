export const tableColumnData = {
	// 常规化学成分
	'21': [{
		label: '样品编码',
		prop: 'sampleCode',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '年份',
		prop: 'year',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '省份',
		prop: 'province',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '洲市',
		prop: 'city',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '县区',
		prop: 'county',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '乡镇',
		prop: 'town',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '品种',
		prop: 'varieties',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '等级',
		prop: 'grade',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '总糖(%)',
		prop: 'totalSugar',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '钾离子(%)',
		prop: 'potassiumIon',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '还原糖(%)',
		prop: 'reducingSugar',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '总植物碱(%)',
		prop: 'totalAlkaloids',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '氯离子(%)',
		prop: 'chlorideIon',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '总氮(%)',
		prop: 'totalNitrogen',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '淀粉(%)',
		prop: 'starch',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '石油醚提取物(%)',
		prop: 'petroleumEtherExtract',
		width: '',
		align: 'center',
		show: true
	}, {
		label: 'pH值',
		prop: 'pH',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '钾氯比',
		prop: 'potassiumChlorideRatio',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '糖碱比',
		prop: 'sugarAlkaliRatio',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '氮碱比',
		prop: 'nitrogenAlkaliRatio',
		width: '',
		align: 'center',
		show: true
	}, {
		label: '综合评价',
		prop: 'evaluate',
		width: '',
		align: 'center',
		show: true
	}],
	//外观品质
	'22':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'year',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'city',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'county',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'town',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '品种',
			prop: 'varieties',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '等级',
			prop: 'grade',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '颜色',
			prop: 'color',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '成熟度',
			prop: 'maturity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '叶片结构',
			prop: 'bladeStructure',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '身份',
			prop: 'identity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '油份',
			prop: 'oil',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '色度',
			prop: 'chroma',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '长度',
			prop: 'length',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '宽度',
			prop: 'width',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '残伤',
			prop: 'mutilation',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'evaluate',
			width: '',
			align: 'center',
			show: true
		},
	],
	//物理特性
	'23':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'year',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'city',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'county',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'town',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '品种',
			prop: 'varieties',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '等级',
			prop: 'grade',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '厚度(mm)',
			prop: 'thickness',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '含梗率(%)',
			prop: 'stemPercentage',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '页面密度(g/m2)',
			prop: 'pageDensity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '抗张力(N)',
			prop: 'tensionResistance',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '抗张强度(KN/m)',
			prop: 'tensileStrength',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '平衡含水率(%)',
			prop: 'equilibriumMoistureContent',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '填充值(cm3/g)',
			prop: 'fillValue',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '单叶重(g)',
			prop: 'singleLeafWeight',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'evaluate',
			width: '',
			align: 'center',
			show: true
		},
	],
	//感官评析
	'24':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'year',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'city',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'county',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'town',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '品种',
			prop: 'varieties',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '等级',
			prop: 'grade',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '香型',
			prop: 'flavorType',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '香气质',
			prop: 'aromaValue',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '香气量',
			prop: 'aromaQuantity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '杂气',
			prop: 'miscellaneousGas',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '浓度',
			prop: 'concentration',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '刺激性',
			prop: 'thrill',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '余味',
			prop: 'aftertaste',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '总分',
			prop: 'totalScore',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '灰色',
			prop: 'grey',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '燃烧性',
			prop: 'flammability',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '使用价值',
			prop: 'useValue',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '劲头',
			prop: 'energy',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '感官品质描述',
			prop: 'qualityDescribe',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'evaluate',
			width: '',
			align: 'center',
			show: true
		},
	],
	//色素多酚
	'25':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'year',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'city',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'county',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'town',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '品种',
			prop: 'varieties',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '等级',
			prop: 'grade',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '类胡萝卜素',
			prop: 'carotenoids',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'β-胡萝卜素',
			prop: 'carotene',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '绿原酸',
			prop: 'chlorogenicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '莨菪亭',
			prop: 'hyoscyamine',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '芸香苷',
			prop: 'rutin',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'evaluate',
			width: '',
			align: 'center',
			show: true
		},
	],
	//有机酸
	'26':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'year',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'city',
			width: '',
			align: 'center',
			show: true,
		},
		{
			label: '区县',
			prop: 'county',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'town',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '品种',
			prop: 'varieties',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '等级',
			prop: 'grade',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '异戊酸',
			prop: 'lsovalericAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'A2_甲基丁酸',
			prop: 'methylButyricAcidA2',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '戊酸',
			prop: 'valericAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'A3_甲基戊酸',
			prop: 'methylValericAcidA3',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '己酸',
			prop: 'caproicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯甲酸',
			prop: 'benzoicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '辛酸',
			prop: 'bitter',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯乙酸',
			prop: 'phenylaceticAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '草酸',
			prop: 'oxalate',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '丙二酸',
			prop: 'malonicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '丁二酸',
			prop: 'succinicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苹果酸',
			prop: 'malicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '柠檬酸',
			prop: 'citricAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '棕榈酸',
			prop: 'palmitiicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '亚油酸',
			prop: 'linoleicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '油酸',
			prop: 'oleicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '亚麻酸',
			prop: 'linolenicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '硬脂酸',
			prop: 'stearicAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'evaluate',
			width: '',
			align: 'center',
			show: true
		},
	],
	//中性致香成分
	'27':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'year',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'city',
			width: '',
			align: 'center',
			show: true,
		},
		{
			label: '区县',
			prop: 'county',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'town',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '品种',
			prop: 'varieties',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '等级',
			prop: 'grade',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1-戊烯-3-酮',
			prop: 'pentenone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '3-羟基-2-丁酮',
			prop: 'hydroxybutanone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '3-甲基-1-丁醇',
			prop: 'methylButanol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '吡啶',
			prop: 'pyridine',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '3-甲基-2-丁烯醛',
			prop: 'methylButyraldehyde',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '几醛',
			prop: 'severalAldehydes',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '面包酮',
			prop: 'breadKetone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '糠醛',
			prop: 'furfural',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '糠醇',
			prop: 'furfurylAlcohol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2-环戊烯-1，4-二酮',
			prop: 'cyclopentenedione',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1-(2-呋喃基）-乙酮',
			prop: 'furanylEthylKetone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '丁丙酯',
			prop: 'butylPropylEster',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2-吡啶甲醛',
			prop: 'pyridineFormaldehyde2',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '糠酸',
			prop: 'furoisAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯甲醛',
			prop: 'benzaldehyde',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '5-甲基糠醛',
			prop: 'methylFurfural',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2,4-庚二甲醛',
			prop: 'heptadecaldehyde',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '4-吡啶甲醛',
			prop: 'pyridineFormaldehyde4',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1H-吡咯-2-甲醛',
			prop: 'pyrrolaldehyde',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2,4-庚二烯醛B',
			prop: 'heptadieneAldehyde',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯甲醇',
			prop: 'benzylAlcohol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯乙醛',
			prop: 'phenylacetaldehyde',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1-(1-H-吡咯-2-基)-乙酮',
			prop: 'pyrrolidone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '芳樟醇',
			prop: 'linalool',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '壬醛',
			prop: 'nonanal',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1-(3-吡啶基）-乙酮',
			prop: 'pyridylEthylKetone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯乙醇',
			prop: 'phenylethanol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '氧化异佛尔酮+未知物',
			prop: 'isophoroneOxide',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2,6壬二烯醛',
			prop: 'nonadienal',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯丙[b]噻酚',
			prop: 'phenylpropylthiophenol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '藏花醛',
			prop: 'safflowerAldehyde',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '胡薄荷酮',
			prop: 'menthol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2,3-二氢苯丙呋喃',
			prop: 'dihydrophenylpropylFuran',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '吲哚',
			prop: 'indole',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2-甲氧基-4-乙烯基苯酚',
			prop: 'vinylPhenol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '茄酮',
			prop: 'solanone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'β-大马酮',
			prop: 'damascusKetone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'β-二氢大马酮',
			prop: 'dihydrodamascusKetone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '去氢去甲基烟碱',
			prop: 'dehydromethylNicotine',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '香叶基丙酮',
			prop: 'geranylAcetone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'β-紫罗兰酮',
			prop: 'lonone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '丁基化羟基甲苯',
			prop: 'butylatedHydroxytoluene',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2,3-联吡啶',
			prop: 'bipyridine',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '二氢猕猴桃内酯',
			prop: 'dihydrokiwiLactone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '巨豆三烯酮A',
			prop: 'pachytene1',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '巨豆三烯酮B',
			prop: 'pachytene2',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '巨豆三烯酮C',
			prop: 'pachytene3',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '巨豆三烯酮D',
			prop: 'pachytene4',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '3-氧代-α-紫罗兰醇',
			prop: 'violetAlcohol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '十四醛',
			prop: 'tetradecanal',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2,3,6-三甲基-1，4-萘二酮（降茄二酮）',
			prop: 'naphthaleneDiketone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '蒽',
			prop: 'anthracene',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '茄那士酮',
			prop: 'solanesone',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '新植二烯',
			prop: 'neophytodiene',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '邻苯二甲酸二丁酯',
			prop: 'dibutylPhthalate',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '金合欢基丙酮A',
			prop: 'farnesylAcetone1',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '十六酸甲酯（棕榈酸甲酯）',
			prop: 'methylHexadecanoate',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '十六酸（棕榈酸）',
			prop: 'palmiticAcid',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '十六酸乙酯（棕榈酸乙酯）',
			prop: 'ethylPalmitate',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '寸拜醇',
			prop: 'inchoateAlcohol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '亚麻酸甲酯',
			prop: 'methylLinolenate',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '植醇',
			prop: 'phytol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '西柏三烯二醇',
			prop: 'cyprotrieneGlycol',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '金合欢基丙酮B',
			prop: 'farnesylAcetone2',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '质体色素降解产物',
			prop: 'plastidPigment',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '苯丙氨酸类降解产物',
			prop: 'phenylalanine',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '西柏烷类降解产物',
			prop: 'siberian',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '棕色化反应产物',
			prop: 'browningReactionProducts',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'evaluate',
			width: '',
			align: 'center',
			show: true
		},
	],

	//地理环境
	'1':[
		{
			label: '代码',
			prop: 'code',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'stateCity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'distinctCounty',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'townShip',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '纬度（N）',
			prop: 'latitude',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '经度（E）',
			prop: 'longitude',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '海拔（m）',
			prop: 'altitude',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '耕地面积（万亩）',
			prop: 'cultivatedLandArea',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '旱地面积（万亩）',
			prop: 'dryLandArea',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '山区占比（%）',
			prop: 'mountainousProportion',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '人口（万人）',
			prop: 'population',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '国土面积（km2）',
			prop: 'landArea',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '村委会',
			prop: 'villageCommittee',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'comprehensiveEvaluation',
			width: '',
			align: 'center',
			show: true
		},
	],
	//气象-降雨量
	'2':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'particularYear',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'stateCity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'distinctCounty',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年降雨量',
			prop: 'annualRainfall',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '大田期降雨量',
			prop: 'filedRainfall',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1月',
			prop: 'january',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2月',
			prop: 'february',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '3月',
			prop: 'march',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '4月',
			prop: 'april',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '5月',
			prop: 'may',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '6月',
			prop: 'june',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '7月',
			prop: 'july',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '8月',
			prop: 'august',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '9月',
			prop: 'september',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '10月',
			prop: 'october',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '11月',
			prop: 'november',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '12月',
			prop: 'december',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'comprehensiveEvaluation',
			width: '',
			align: 'center',
			show: true
		},
	],
	//气象-气温
	'3':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'particularYear',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'stateCity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'distinctCounty',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年平均温度',
			prop: 'annualAverageTemperature',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '大田期平均温度',
			prop: 'filedAverageTemperature',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1月',
			prop: 'january',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2月',
			prop: 'february',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '3月',
			prop: 'march',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '4月',
			prop: 'april',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '5月',
			prop: 'may',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '6月',
			prop: 'june',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '7月',
			prop: 'july',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '8月',
			prop: 'august',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '9月',
			prop: 'september',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '10月',
			prop: 'october',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '11月',
			prop: 'november',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '12月',
			prop: 'december',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '10℃的年活动积温',
			prop: 'activeTemperature',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '无霜期',
			prop: 'noFrost',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'comprehensiveEvaluation',
			width: '',
			align: 'center',
			show: true
		},
	],
	//气象-日照时数
	'4':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'particularYear',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'stateCity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'distinctCounty',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年日时数（h）',
			prop: 'annualSunshineHours',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '大田期日照时数（h）',
			prop: 'filedSunshineHours',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '1月（h）',
			prop: 'january',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '2月（h）',
			prop: 'february',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '3月（h）',
			prop: 'march',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '4月（h）',
			prop: 'april',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '5月（h）',
			prop: 'may',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '6月（h）',
			prop: 'june',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '7月（h）',
			prop: 'july',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '8月（h）',
			prop: 'august',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '9月（h）',
			prop: 'september',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '10月（h）',
			prop: 'october',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '11月（h）',
			prop: 'november',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '12月（h）',
			prop: 'december',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '大田期日照百分率（%）',
			prop: 'sunshinePercentage',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'comprehensiveEvaluation',
			width: '',
			align: 'center',
			show: true
		},
	],
	//土壤-常规养分
	'5':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'particularYear',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'stateCity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'distinctCounty',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'townShip',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '样点',
			prop: 'samplePoint',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '土壤类型',
			prop: 'soilType',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'pH值',
			prop: 'phValue',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '有机质',
			prop: 'organicMatter',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '全氮',
			prop: 'totalNitrogen',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '全磷',
			prop: 'totalPhosphorus',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '全钾',
			prop: 'totalPotassium',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '碱减氮',
			prop: 'alkaliHydrolyzedNitrogen',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '速效磷',
			prop: 'availablePhosphorus',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '氯离子',
			prop: 'chlorideIon',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '有效硼',
			prop: 'availableBoron',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '交换性镁',
			prop: 'exchangeableMagnesium',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'comprehensiveEvaluation',
			width: '',
			align: 'center',
			show: true
		},
	],
	//土壤-农残
	'6':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'particularYear',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'stateCity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'distinctCounty',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'townShip',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '样点',
			prop: 'samplePoint',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '土壤类型',
			prop: 'soilType',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'pH值',
			prop: 'phValue',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '六六六',
			prop: 'six',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '滴滴涕',
			prop: 'ddt',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '甲胺磷',
			prop: 'methamidophos',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'comprehensiveEvaluation',
			width: '',
			align: 'center',
			show: true
		},
	],
	//土壤-重金属
	'7':[
		{
			label: '样品编码',
			prop: 'sampleCode',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '年份',
			prop: 'particularYear',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '省份',
			prop: 'province',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '州市',
			prop: 'stateCity',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '区县',
			prop: 'distinctCounty',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '乡镇',
			prop: 'townShip',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '样点',
			prop: 'samplePoint',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '土壤类型',
			prop: 'soilType',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '铬',
			prop: 'chromium',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '砷',
			prop: 'arsenic',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '镉',
			prop: 'cadmium',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '汞',
			prop: 'mercury',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '铅',
			prop: 'lead',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '铜',
			prop: 'copper',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: 'pH值',
			prop: 'phValue',
			width: '',
			align: 'center',
			show: true
		},
		{
			label: '综合评价',
			prop: 'comprehensiveEvaluation',
			width: '',
			align: 'center',
			show: true
		},
	],
}
